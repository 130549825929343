<template>
  <div id="extra-component-chartist-demo">
      <div class="vx-row" v-show="showAppSection">
          <h4 class="vx-col w-full">{{ getResourcesWithStatisticsDrm('app').length === 1 ? $t('dashboard.app') : $t('dashboard.apps') }}</h4>
          <vs-divider class="vx-col w-full"/>

          <dashboard-statistics-slider
            resource-type="app"
            :statistic-key="statisticKey"
            @loaded="showAppSection = true"
            v-for="statisticKey in statisticsKeys('app')"
            :key="statisticKey"
          />
      </div>

      <div class="vx-row" v-show="showResortSection">
          <h4 class="vx-col w-full">{{ getResourcesWithStatisticsDrm('resort').length === 1 ? $t('dashboard.resort') : $t('dashboard.resorts') }}</h4>
          <vs-divider class="vx-col w-full"/>
          <dashboard-statistics-slider
            resource-type="resort"
            :statistic-key="statisticKey"
            @loaded="showResortSection = true"
            v-for="statisticKey in statisticsKeys('resort')"
            :key="statisticKey"
          />
      </div>

      <div class="vx-row" v-show="showShopSection">
          <h4 class="vx-col w-full">{{ getResourcesWithStatisticsDrm('shop').length === 1 ? $t('dashboard.shop') : $t('dashboard.shops') }}</h4>
          <vs-divider class="vx-col w-full"/>
          <dashboard-statistics-slider
            resource-type="shop"
            :statistic-key="statisticKey"
            @loaded="showShopSection = true"
            v-for="statisticKey in statisticsKeys('shop')"
            :key="statisticKey"
          />
      </div>
  </div>
</template>

<script>

import { getResourcesWithStatisticsDrm} from "@/modules/Statistics/Services/StatisticsService";
import StatisticCard from "@/modules/Statistics/Components/StatisticCard";
import { mapGetters } from "vuex";
import DashboardStatisticsSlider from "@/modules/Statistics/Components/DashboardStatisticsSlider";

export default {
  data () {
    return {
      getResourcesWithStatisticsDrm: getResourcesWithStatisticsDrm,
      showAppSection: false,
      showResortSection: false,
      showShopSection: false,
    }
  },
  computed: {
    ...mapGetters("statistics", ["statisticsKeys"]),
  },
  components: {
    DashboardStatisticsSlider,
    StatisticCard
  },

}
</script>
