<template>
  <div v-show="anyLoaded" class="vx-col md:w-1/2 w-full mb-base">
    <swiper class="h-full dashboard" :options="swiperOption">
      <swiper-slide class="h-full" 
        v-for="(stat, index) in dashboardStatistics" :key="index"
      >
        <statistic-card
          class="vx-col w-full mb-base h-full"
          :resourceType="resourceType"
          :resourceUuid="stat.resourceUuid"
          :statisticKey="statisticKey"
          @loaded="anyLoaded = true; $emit('loaded')"
        />
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-bullets" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

  import StatisticCard from "@/modules/Statistics/Components/StatisticCard";
  import { mapGetters } from "vuex";
  import {fetchStatistics} from "@/modules/Statistics/Services/StatisticsService";

  export default {
    components: {
      StatisticCard
    },
    props: {
      resourceType: String,
      statisticKey: String
    },
    data () {
      const self = this;

      return {
        dashboardStatistics: undefined,
        anyLoaded: false,
        componentKey: 0,
        swiperOption: {
          autoplay: {
            delay: 15000,
            disableOnInteraction: true
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 5,
            renderBullet(index, className) {
              const statistics = self.$store.getters['statistics/statistic'](self.statisticKey, self.resourceType, null);
              if (statistics.length === 1) return '';
              return `<span class="${className} swiper-pagination-bullet-custom px-2">
                    ${ statistics[index].resourceName }
                </span>`
            },
          },
          allowTouchMove: false
        }
      }
    },
    computed: {
      ...mapGetters("statistics", ["statisticsKeys", "statistic"]),
    },

    async created() {
      await fetchStatistics(this.statisticKey, this.resourceType, null)
      this.dashboardStatistics = this.statistic(this.statisticKey, this.resourceType, null)
    }

  };
</script>

<style lang="scss">

  .dashboard .swiper-pagination-bullets {
    bottom: 10px !important;
  }

  .swiper-pagination-bullet-custom {
    font-size: 10px;
    $size: 20px;
    width: auto !important;
    height: $size !important;
    line-height: $size !important;
    text-align: center;
    opacity: 0.7;
    transition: all 0.3s;
    border-radius: 50px !important;
    cursor: pointer;
    color: white;

    &:hover {
      opacity: 1;
    }

    &.swiper-pagination-bullet-active {
      opacity: 1;
      background: #007aff;
    }
  }
</style>
