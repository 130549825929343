var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.anyLoaded,
          expression: "anyLoaded"
        }
      ],
      staticClass: "vx-col md:w-1/2 w-full mb-base"
    },
    [
      _c(
        "swiper",
        {
          staticClass: "h-full dashboard",
          attrs: { options: _vm.swiperOption }
        },
        [
          _vm._l(_vm.dashboardStatistics, function(stat, index) {
            return _c(
              "swiper-slide",
              { key: index, staticClass: "h-full" },
              [
                _c("statistic-card", {
                  staticClass: "vx-col w-full mb-base h-full",
                  attrs: {
                    resourceType: _vm.resourceType,
                    resourceUuid: stat.resourceUuid,
                    statisticKey: _vm.statisticKey
                  },
                  on: {
                    loaded: function($event) {
                      _vm.anyLoaded = true
                      _vm.$emit("loaded")
                    }
                  }
                })
              ],
              1
            )
          }),
          _c("div", {
            staticClass: "swiper-pagination swiper-pagination-bullets",
            attrs: { slot: "pagination" },
            slot: "pagination"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }