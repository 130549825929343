var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "extra-component-chartist-demo" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showAppSection,
            expression: "showAppSection"
          }
        ],
        staticClass: "vx-row"
      },
      [
        _c("h4", { staticClass: "vx-col w-full" }, [
          _vm._v(
            _vm._s(
              _vm.getResourcesWithStatisticsDrm("app").length === 1
                ? _vm.$t("dashboard.app")
                : _vm.$t("dashboard.apps")
            )
          )
        ]),
        _c("vs-divider", { staticClass: "vx-col w-full" }),
        _vm._l(_vm.statisticsKeys("app"), function(statisticKey) {
          return _c("dashboard-statistics-slider", {
            key: statisticKey,
            attrs: { "resource-type": "app", "statistic-key": statisticKey },
            on: {
              loaded: function($event) {
                _vm.showAppSection = true
              }
            }
          })
        })
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showResortSection,
            expression: "showResortSection"
          }
        ],
        staticClass: "vx-row"
      },
      [
        _c("h4", { staticClass: "vx-col w-full" }, [
          _vm._v(
            _vm._s(
              _vm.getResourcesWithStatisticsDrm("resort").length === 1
                ? _vm.$t("dashboard.resort")
                : _vm.$t("dashboard.resorts")
            )
          )
        ]),
        _c("vs-divider", { staticClass: "vx-col w-full" }),
        _vm._l(_vm.statisticsKeys("resort"), function(statisticKey) {
          return _c("dashboard-statistics-slider", {
            key: statisticKey,
            attrs: { "resource-type": "resort", "statistic-key": statisticKey },
            on: {
              loaded: function($event) {
                _vm.showResortSection = true
              }
            }
          })
        })
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showShopSection,
            expression: "showShopSection"
          }
        ],
        staticClass: "vx-row"
      },
      [
        _c("h4", { staticClass: "vx-col w-full" }, [
          _vm._v(
            _vm._s(
              _vm.getResourcesWithStatisticsDrm("shop").length === 1
                ? _vm.$t("dashboard.shop")
                : _vm.$t("dashboard.shops")
            )
          )
        ]),
        _c("vs-divider", { staticClass: "vx-col w-full" }),
        _vm._l(_vm.statisticsKeys("shop"), function(statisticKey) {
          return _c("dashboard-statistics-slider", {
            key: statisticKey,
            attrs: { "resource-type": "shop", "statistic-key": statisticKey },
            on: {
              loaded: function($event) {
                _vm.showShopSection = true
              }
            }
          })
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }